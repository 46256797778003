import { SvgSprite } from '../classes/svgLoader';
import { Accordion } from '../classes/accordion';
import { Modal } from '../classes/modal';
import { nodeListToArray } from '../helpers/nodeListToArray';

// Code shared between Corporate & Careers/Sustainability

const initAll = () => {
    new SvgSprite('/Static-Adage/img/adage-sprite.svg');
    initAccordions();
    initModals();
}

const initAccordions = () => {
    // Accordion Init
    const accordions = [].slice.call(document.querySelectorAll('[data-accordion-list]'));
    accordions.forEach(accordion => {
        const oneOpen = accordion.getAttribute('data-one-open-at-a-time');
        new Accordion({
            container: accordion,
            oneOpenAtATime: oneOpen === 'True'
        });
    });
}

const initModals = () => {
    // Modals Init
    const modals = nodeListToArray(document.querySelectorAll('[data-modal]'));
    modals.forEach(modal => {
        new Modal(modal, false);
    });
}

initAll();