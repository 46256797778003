let debounce = require('lodash.debounce');
import { enableTabbableChildren } from '../helpers/enableTabbableChildren';
import { disableTabbableChildren } from '../helpers/disableTabbableChildren';
let assign = require('lodash.assign');
import { getHeightOfHidden } from '../helpers/getHeightOfHidden';
import { Promise } from 'es6-promise';

export interface IAccordionOptions {
    container?: HTMLElement;
    oneOpenAtATime: boolean;
}

export class Accordion {
    options: IAccordionOptions = {
        oneOpenAtATime: true
    }

    container: HTMLElement;
    panels: Array<HTMLElement>;
    triggers: Array<HTMLButtonElement>;
    activePanel: any;
    activeTriggers: Array<HTMLButtonElement>;
    images: Array<HTMLImageElement>;

    constructor(options: IAccordionOptions) {
        assign(this.options, options);
        this.container = this.options.container;
        this.panels = [].slice.call(this.container.querySelectorAll('[role="region"]'));
        this.triggers = [].slice.call(this.container.querySelectorAll('[aria-controls], [data-controls]'));
        this.images = [];

        window.addEventListener(
            'resize',
            debounce(() => {
                this.setAccordionHeights();
            }, 100)
        );

        this.triggers.forEach(trigger => {
            trigger.addEventListener('click', this.accordionClick.bind(this));
        });
        this.panels.forEach(panel => {
            disableTabbableChildren(panel);
            const panelImages = Array.prototype.slice.call(panel.querySelectorAll('img'));
            this.images = this.images.concat(panelImages);
        });
        setTimeout(() => {
            this.setAccordionHeights();

            // Automatically open panel if the heading is targeted in page hash
            const hash = window.location.hash;
            if (hash) {
                const hashElement = document.querySelector(`${hash} > button`) as HTMLButtonElement;
                if (hashElement) {
                    this.triggers.forEach(trigger => {
                        if (trigger === hashElement) {
                            hashElement.click();
                        }
                    });
                }
            }

            Promise.all(this.images.map(image => {
                const path = image.src;
                return new Promise(resolve => {
                    let img = new Image();
                    img.onload = () => resolve(path);
                    img.onerror = () => resolve(path);
                    img.src = path;
                })
            })).then(() => {
                this.setAccordionHeights();
            });
        }, 0);
    }

    setAccordionHeights() {
        this.panels.forEach(panel => {
            // override css styles so that we can capture the actual height of the collapsed panel
            const panelHeight = getHeightOfHidden(panel);
            panel.style.height = `${panelHeight}px`;
        });
    }

    accordionClick(e) {
        const id = e.target.hasAttribute('aria-controls') ? e.target.getAttribute('aria-controls') : e.target.getAttribute('data-controls');
        this.activePanel = this.container.querySelector(`#${id}`);
        this.activeTriggers = [].slice.call(document.querySelectorAll(`[aria-controls="${id}"]`));
        // focus on top trigger
        this.activeTriggers[0].focus();
        this.toggleActivePanel();
        if (this.options.oneOpenAtATime) {
            this.closeInactivePanels();
        }
    }

    toggleActivePanel() {
        if (this.activeTriggers[0].getAttribute('aria-expanded') === 'true') {
            this.closePanel(this.activeTriggers, this.activePanel);
        }
        else {
            this.openPanel(this.activeTriggers, this.activePanel);
        }
        if (this.options.oneOpenAtATime) {
            this.closeInactivePanels();
        }
    }

    closeInactivePanels() {
        this.triggers.forEach(trigger => {
            let contains = false;
            this.activeTriggers.forEach(activeTrigger => {
                if (trigger == activeTrigger) {
                    contains = true;
                }
            })
            if (!contains) {
                let curPanel = this.container.querySelector(`#${trigger.getAttribute('aria-controls')}`);
                this.closePanel(trigger, curPanel);
            }
        });
    }

    openPanel(triggers, panel) {
        triggers.forEach(trigger => {
            trigger.setAttribute('aria-expanded', 'true');
        });
        panel.setAttribute('aria-hidden', 'false');
        enableTabbableChildren(panel);
    }

    closePanel(triggers, panel) {
        triggers.forEach(trigger => {
            trigger.setAttribute('aria-expanded', 'false');
        });
        panel.setAttribute('aria-hidden', 'true');
        disableTabbableChildren(panel);
    }
}
