//import * as CONSTANTS from '../modules/constants';
import * as A11yDialog from 'a11y-dialog';
import * as YouTubeIframeLoader from 'youtube-iframe';

export class Modal {
	modal: any;
	modalElement: HTMLElement;
	video?: any;
    muteVideo: boolean;
    bodyElement: HTMLElement;
    constructor(modalElement: HTMLElement, muteVideo?: boolean, container?: HTMLElement) {
		let args = container != null ? [modalElement, container] : [modalElement];
		this.modal = new A11yDialog(...args);
        this.modalElement = modalElement;
        this.muteVideo = muteVideo; 
        this.bodyElement = document.querySelector('body');
        this.modal.on('show', () => {
            this.bodyElement.classList.add('adage-modal-open');
            this.bodyElement.appendChild(this.modalElement);
            const title = (this.modalElement.querySelector(`[data-a11y-dialog-title]`) as HTMLElement);
            if(title)
                title.focus();
			this.initVideo();
		});
        this.modal.on('hide', () => {
            this.bodyElement.classList.remove('adage-modal-open');
            this.pauseVideo();
        });
	}

	initVideo() {
        let video = this.modalElement.querySelector('[data-video-type]');
		if (video) {
			let videoType = video.getAttribute('data-video-type');
            if (videoType == 'youtube')
				this.youtubeInit();
			else
				this.nativeVideoInit();
		}
	}

	youtubeInit() {
        let youtubeIframe = this.modalElement.querySelector('iframe');
		if (!youtubeIframe.src) {
			let uniqueID = this.modalElement.id;
			YouTubeIframeLoader.load(YT => {
				let playerID = `youtube-player-${uniqueID}`;
				youtubeIframe.id = playerID;
                youtubeIframe.src = youtubeIframe.getAttribute('data-src');
                //Init Video and play
                let youtubePlayer = new YT.Player(playerID, {
                    events: {
                        'onReady': () => {
                            if (this.muteVideo)
                                youtubePlayer.mute();
                            //youtubePlayer.playVideo();
                        }
                    }
                });
                this.video = youtubePlayer;  
            });
		} 
	}

	nativeVideoInit() {
        let videoElement = this.modalElement.querySelector('video');
		if (videoElement) {
			let videoSource = videoElement.querySelector('source');
			if (!videoSource.src) {
				videoSource.src = videoSource.getAttribute('data-src');
				videoElement.load();
			}
			this.video = videoElement;
		}
	}

	pauseVideo() {
        let videoContainer = this.modalElement.querySelector('[data-video-type]');
        if (videoContainer) {
            if (videoContainer.getAttribute('data-video-type') == 'vimeo') {
                this.video.pause();
            }
            else if (videoContainer.getAttribute('data-video-type') == 'youtube') {
                this.video.pauseVideo();
            }
            else {
                if (!this.video.paused) {
                    this.video.pause();
                }
            }
        }
	}
}